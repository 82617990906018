import React, { useEffect } from 'react'
import Image from '../image'
import { useTranslation } from 'react-i18next'

const Gallery = ({ pictures, credits, creditsVideo, title, title2 }) => {

  useEffect(() => {
    console.log(credits)
  }, [])

  const
    { t } = useTranslation('project'),
    getPictures = pictures.gallery.slice(0, 13)

  const getSpeed = (i) => {
    switch (i) {
      case 0:
      case 1:
      case 3:
      case 6:
      case 9:
      case 12:
        return "1"
      case 2:
      case 7:
      case 10:
        return "2"
      case 4:
      case 8:
      case 11:
        return "3"
      default:
        return "4"
    }
  }

  return (
    <div className="project-gallery grid" data-scroll-section>
      {pictures && getPictures.map((picture, i) => {
        return (
          <div key={i} data-scroll data-scroll-speed={getSpeed(i)} className="picture" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
            <Image fluid={picture.asset.fluid} alt={`${title} ${title2} | Gutiérrez F Studio`} />
          </div>
        )
      })}

      <div className='credits-wrapper' data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        {credits && credits.length !== 0 && (
          <div className="credits-item">
            <p>{t('credits')}
              {credits.map((credit, i) => {
                return (
                  <a key={i} href={credit.url} target="__blank"> {credit.name}{credits.length > 1 && (credit !== credits[credits.length - 1]) ? ',' : ''}</a>
                )
              })}
            </p>
          </div>
        )}

        {creditsVideo && creditsVideo.length !== 0 && (
          <div className="credits-item">
            <p>{t('credits_video')}
              {creditsVideo.map((credit, i) => {
                return (
                  <a key={i} href={credit.url} target="__blank"> {credit.name}{creditsVideo.length > 1 && credit !== creditsVideo[creditsVideo.length - 1] ? ',' : ''}</a>
                )
              })}
            </p>
          </div>
        )}
      </div>

    </div>
  )
}

export default Gallery
