import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Hero from '../components/project/hero'
import Gallery from '../components/project/gallery'
import Controls from '../components/project/controls'

const ProjectPage = ({ data, pageContext }) => {
  const
    { title, title2, vimeoLink, pictures, credits, creditsVideo } = data.allSanityProject.nodes[0],
    projectLocation = data.allSanityProject.nodes[0].location;

  useEffect(() => {
    const wrapper = document.querySelector('#gatsby-focus-wrapper')
    wrapper.style.backgroundColor = "#F7F5F4"
  })

  return (
    <>
      <SEO title={`${title} ${title2}`} lang={pageContext.language}/>
      <Hero title={title} title2={title2} video={vimeoLink} location={projectLocation} />
      <Gallery title={title} title2={title2} pictures={pictures} credits={credits} creditsVideo={creditsVideo} />
      <Controls next={pageContext.next} />
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allSanityProject(filter: {slug: {current: {eq: $slug}}}) {
      nodes {
        title
        title2
        cover {
          asset {
            fluid(maxWidth: 1580) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        location
        vimeoLink
        pictures {
          gallery {
            asset {
              fluid(maxWidth: 1980) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
        credits {
          name
          url
        }
        creditsVideo {
          name
          url
        }
      }
    }
  }
`

export default ProjectPage
